import { createSlice } from '@reduxjs/toolkit';
import { ExtraReducerTextMarketing, ReducerTextMarketing } from '../Reducers/TextMarketingReducers';
import { StatusEnum } from '../../Vagaroconnect/Binding/ChatBoatEnums';

const TextMarketingSlice = createSlice({
  name: 'TextMarketing',
  initialState: {
    TextMarketingEnable: null,
    TriggerTextMarketingEnable: false,
    DeactiveAi: false,
    Usage: null,
    PlanUsageLimit: null,
    MessengerUsage: null,
    ActiveSMSForMessenger: null,
    FromTextMessengerPhone: null,
    NumberStatus: null,
    PlanName: null,
    CountryId: null,
    OverLimitRateInCentsText: null,
    Percentage: null,
    Variant: null,
    Status: StatusEnum.Idle

  },
  reducers: ReducerTextMarketing,
  extraReducers: ExtraReducerTextMarketing,
});
export const { TriggerEnableTextMaketing, DeactiveAi } = TextMarketingSlice.actions;
export const TextMarketingReducer = TextMarketingSlice.reducer;
