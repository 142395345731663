import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit'
import useTokenReducer from '../component/Slice/Token'
import storage from 'redux-persist/lib/storage'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import { TypingUserReducer } from '../component/Slice/TypingUser'
import { BusinessSettingReducer } from '../component/Slice/BusinessSettings'
import { TextMarketingReducer } from '../component/Slice/TextMarketingSlice'


const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['Token']
}
export const rootReducers = combineReducers({
    Token: useTokenReducer,
    TypingUser: TypingUserReducer,
    Settings: BusinessSettingReducer,
    TextMarketing: TextMarketingReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducers)
const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})

export default store