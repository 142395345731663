import React from 'react'
//Local
//export const ApiV2Url = "https://apiv2.localdev.com/us02/api/v2/";
//export const VagaroConnectApiUrl = "https://connect-api.localdev.com/us02/api/v2/";
//export const SignalRUrl = "https://connect-api.localdev.com";

//Dev22
// export const ApiV2Url = "https://dev22apiv2.bookitall.com/us02/api/v2/";
// export const VagaroConnectApiUrl = "https://connect-api.bookitall.com/us02/api/v2/";
// export const SignalRUrl = "https://connect-api.bookitall.com";

//Demo
export const ApiV2Url = "https://as-dev-demo-api.azurewebsites.net/us02/api/v2/";
export const VagaroConnectApiUrl = "https://democonnect-api.bookitall.com/us02/api/v2/";
export const SignalRUrl = "https://democonnect-api.bookitall.com";

