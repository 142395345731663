import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull'
import Dropdown from 'react-bootstrap/Dropdown';
import { LinkWithTooltip, utils } from '../../utils/Utils';
import { MobileCallBackAction } from '../Binding/ChatBoatEnums';

const VideoPlayer = ({ URL, Status, FileName, Type, Preview, Connectapp, isIOSMobile, isAndroidIOSMobile }) => {
  const [ready, setReady] = useState(false);
  const [canvasReady, setCanvasReady] = useState(false);
  const [startplayer, setStartplayer] = useState(false);
  const [toggleStartplayer, setToggleStartplayer] = useState(false);
  const [faileToLoad, setFaileToLoad] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [videoRange, setVideoRange] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [hasaudio, setHasaudio] = useState(true);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loadProgress, setLoadProgress] = useState(0);
  const [pip, setPip] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [isOpen, setIsOpen] = useState(false);
  const playerRef = useRef(null);
  const playerProcessRef = useRef(null);
  const ReplayplayerRef = useRef(false);
  const playerWrapperRef = useRef(null);
  const FullScreenButtonRef = useRef(null);
  let vagaroapp = navigator.userAgent.toLowerCase().indexOf("vagaroapp") > -1

  const togglePlayPause = () => {
    if (playing === false) {
      utils.EventCall("stopPlayer")
    }

    if (Connectapp || vagaroapp) {
      utils.CallBackGivenToMobile(MobileCallBackAction.PalyVideoPlayer, URL);
    }else{
      handlePlayPause();
    }

  };
  const handlePlayPause = () => {
    setPlaying(!playing);
    if (startplayer === false) {
      setStartplayer(true);
    }
    if (videoRange === Infinity && playerProcessRef.current !== null) {
      setVideoRange(playerProcessRef.current);
      setDuration(formatTime(playerProcessRef.current));
      ReplayplayerRef.current = true;
      playerProcessRef.current = null;
    }

  };
  const handleVolumeChange = (e) => {
    if (hasaudio) {
      setMuted(false);
      setVolume(parseFloat(e.target.value));
    }
  };

  const toggleMute = () => {
    if (hasaudio) {
      setMuted(!muted);
    }
  };

  const handleSeekChange = (e) => {
    if (videoRange !== Infinity) {
      const player = parseFloat(e.target.value);
      const Seekto = parseFloat(videoRange * player);
      if (ready) {
        if (isNaN(Seekto) || videoRange === 0) {
          playerRef.current.seekTo(player);
        } else {
          playerRef.current.seekTo(Seekto);
        }
      }
    }
  };

  const handleProgress = ({ played, playedSeconds,loaded }) => {
    setPlayed(played);
    setLoadProgress(loaded)
    setCurrentTime(formatTime(playedSeconds));
    if (videoRange === Infinity && playedSeconds > 0) {
      playerProcessRef.current = playedSeconds
    }
    ReplayplayerRef.current = (Math.round(videoRange) === Math.round(playedSeconds)) ? true : false;
  };

  const handleDuration = (duration) => {
    setVideoRange(duration);
    setDuration(formatTime(duration));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const skipBackwardForward = (time) => {
    if (videoRange !== Infinity) {
      playerRef.current.seekTo(parseFloat(time));
    }
  };

  const handlePlaybackRateChange = (e) => {
    if (videoRange !== Infinity) {
      setPlaybackRate(parseFloat(e));
    }
  };
  const handleError = (error) => {
    console.log("Video Load error",error);
    if (error && error.target && error.target.error && error.target.error.code && error.target.error.code === 4) {
      setFaileToLoad(true)
    }
  };

  const toggleFullscreen = (event) => {
    const { Toggle } = FullScreenButtonRef.current?.dataset
    if (Toggle && Toggle === 'true') {
      FullScreenButtonRef.current.dataset.Toggle = false;
      screenfull.exit();
      if (event.target.className.includes("videoControl")) {
        event.target.children[0].className = 'fa-thin fa-expand';
      } else {
        event.target.className = 'fa-thin fa-expand';
      }
      if (Connectapp === true && isAndroidIOSMobile === false && startplayer) {
        setPlaying(false);
        setStartplayer(false)
      }
    } else {
      FullScreenButtonRef.current.dataset.Toggle = true;
      screenfull.request(playerWrapperRef.current);
      if (event.target.className.includes("videoControl")) {
        event.target.children[0].className = 'fa-light fa-compress';
      } else {
        event.target.className = 'fa-light fa-compress';
      }
    }
  };
  const toggleSmallScreen = () => {
    setPip(!pip);
  };
  const toggleReady = () => {
    setReady(true);
  };
  useEffect(() => {
    if (ready) {
      const video = playerRef.current.getInternalPlayer()
      if ((typeof video.mozHasAudio !== "undefined" && video.mozHasAudio) ||
        (typeof video.webkitAudioDecodedByteCount !== "undefined" && video.webkitAudioDecodedByteCount > 0) ||
        Boolean(video.audioTracks?.length)) {
        console.log("This video has audio tracks.");
        setHasaudio(true);
      } else {
        setHasaudio(false);
        setMuted(true);
        console.log("This video has no audio tracks.");
      }
    }
  }, [ready])
  useEffect(() => {
    if (Connectapp === true && isIOSMobile === false && startplayer && FullScreenButtonRef.current) {
      FullScreenButtonRef.current.click();
    }
  }, [startplayer, toggleStartplayer])



  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        if (screenfull.isFullscreen) {
          // Call your function for entering fullscreen
          console.log('Entered fullscreen');
          if (playerWrapperRef.current?.classList) {
            playerWrapperRef.current.classList.add("fullscreen")
          }
        } else {
          // Call your function for exiting fullscreen
          if (playerWrapperRef.current?.classList) {
            playerWrapperRef.current.classList.remove("fullscreen")
          }
          if (FullScreenButtonRef.current) {
            if (FullScreenButtonRef.current.children[0].className !== 'fa-thin fa-expand') {
              FullScreenButtonRef.current.children[0].className = 'fa-thin fa-expand';
            }
          }
          if (Connectapp === true && isAndroidIOSMobile === false && startplayer) {
            setPlaying(false);
            setStartplayer(true);
          }
          if (Connectapp === true && isAndroidIOSMobile === true && isIOSMobile === false) {
            setPlaying(false);
            setStartplayer(true);
          }
          console.log('Exited fullscreen');
        }
      });

      return () => {
        screenfull.off('change'); // Clean up the event listener on unmount
      };
    }
  }, []);
  useEffect(() => {
    if (playerWrapperRef.current) {
      if (playing) {
        playerWrapperRef.current.classList.add("videoPlaying")
        utils.updateEvent("stopPlayer", () => {
          setPlaying(false);
        })
      } else {
        playerWrapperRef.current.classList.remove("videoPlaying")
      }
    }
  }, [playing])

  const options = [
    { value: 0.5, label: '0.5x' },
    { value: 1, label: '1x' },
    { value: 1.5, label: '1.5x' },
    { value: 2, label: '2x' },
  ];

  return (
    <div ref={playerWrapperRef} className={'videoControlsMainWrap'}>
      <div style={ready ? (Connectapp === true && isIOSMobile === false && canvasReady === false ) ? { display: "none" } : { display: "block" } : { display: "none" }}>
        <ReactPlayer
          ref={playerRef}
          url={URL}
          onReady={() => { toggleReady() }}
          onError={(e) => { handleError(e)}}  
          playing={playing}
          volume={volume}
          muted={muted}
          pip={pip}
          playbackRate={playbackRate}
          onProgress={(process) => handleProgress(process)}
          onDuration={(duration) => handleDuration(duration)}
          onEnded={() => togglePlayPause()}
          controls={false}  // Disable default controls
          // width='100%'
          // height='100%'
          className='videoWraper'
          config={{
            file: {
              attributes: {
                'data-custom-attribute': 'customValue',
                'data-Name': `${FileName || ""}`,
                'data-Type': `${Type || ""}`,
                'data-Url': `${URL || ""}`,
              }
            }
          }}
        />
        {startplayer === false ?
          (Preview || Connectapp === true) ?
            <div className={Connectapp === true && isIOSMobile === false ? 'firstPlay canvasWrap' : 'firstPlay'}>
              {Connectapp === true && isIOSMobile === false && <CanvasImage videoUrl={URL} onReady={setCanvasReady}/>}
              {Connectapp === true ?
                <button onClick={() => togglePlayPause()} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target?.click && e.target?.click() }} className='videoControl videoControl-playPush'>
                  {playing ? <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2866 10.6899L52.3166 26.8356C54.3402 27.8885 55.127 30.3824 54.0741 32.406C53.6826 33.1583 53.069 33.772 52.3166 34.1634L21.2866 50.3091C19.2631 51.362 16.7692 50.5752 15.7163 48.5517C15.41 47.9629 15.25 47.3089 15.25 46.6452V14.3538C15.25 12.0728 17.0992 10.2236 19.3802 10.2236C19.9491 10.2236 20.5108 10.3411 21.0305 10.5676L21.2866 10.6899Z"></path></svg> : <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2866 10.6899L52.3166 26.8356C54.3402 27.8885 55.127 30.3824 54.0741 32.406C53.6826 33.1583 53.069 33.772 52.3166 34.1634L21.2866 50.3091C19.2631 51.362 16.7692 50.5752 15.7163 48.5517C15.41 47.9629 15.25 47.3089 15.25 46.6452V14.3538C15.25 12.0728 17.0992 10.2236 19.3802 10.2236C19.9491 10.2236 20.5108 10.3411 21.0305 10.5676L21.2866 10.6899Z"></path></svg>}
                </button>
                :
                <LinkWithTooltip tooltip="Play Video" placement = "top" isFromConnectApp={Connectapp}>
                  <button onClick={() => togglePlayPause()} onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target?.click && e.target?.click() }} className='videoControl videoControl-playPush'>
                    {playing ? <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2866 10.6899L52.3166 26.8356C54.3402 27.8885 55.127 30.3824 54.0741 32.406C53.6826 33.1583 53.069 33.772 52.3166 34.1634L21.2866 50.3091C19.2631 51.362 16.7692 50.5752 15.7163 48.5517C15.41 47.9629 15.25 47.3089 15.25 46.6452V14.3538C15.25 12.0728 17.0992 10.2236 19.3802 10.2236C19.9491 10.2236 20.5108 10.3411 21.0305 10.5676L21.2866 10.6899Z"></path></svg> : <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2866 10.6899L52.3166 26.8356C54.3402 27.8885 55.127 30.3824 54.0741 32.406C53.6826 33.1583 53.069 33.772 52.3166 34.1634L21.2866 50.3091C19.2631 51.362 16.7692 50.5752 15.7163 48.5517C15.41 47.9629 15.25 47.3089 15.25 46.6452V14.3538C15.25 12.0728 17.0992 10.2236 19.3802 10.2236C19.9491 10.2236 20.5108 10.3411 21.0305 10.5676L21.2866 10.6899Z"></path></svg>}
                  </button>
                </LinkWithTooltip>
              }
            </div>
            :
            <div className='firstPlay fullScreen' onClick={() => window.open(URL, '_blank')}>
            </div>
          :
          <>
            <div style={Connectapp === true && isAndroidIOSMobile === true ? { display: "block" } : { display: "none" }} >
              <div className='firstPlay canvasWrap'>
                <CanvasImage videoUrl={URL} onReady={setCanvasReady}/>
                <LinkWithTooltip tooltip="Play" placement = "top" isFromConnectApp={Connectapp}>
                  <button className='videoControl videoControl-playPush'
                    onClick={() => {
                      togglePlayPause()
                      if (Connectapp === true && isIOSMobile === false) {
                        setToggleStartplayer(prev => !prev)
                      }
                    }}
                    onTouchEnd={(e) => { isIOSMobile && e.cancelable && e.target?.click && e.target?.click() }} >
                    {playing ?
                      <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2866 10.6899L52.3166 26.8356C54.3402 27.8885 55.127 30.3824 54.0741 32.406C53.6826 33.1583 53.069 33.772 52.3166 34.1634L21.2866 50.3091C19.2631 51.362 16.7692 50.5752 15.7163 48.5517C15.41 47.9629 15.25 47.3089 15.25 46.6452V14.3538C15.25 12.0728 17.0992 10.2236 19.3802 10.2236C19.9491 10.2236 20.5108 10.3411 21.0305 10.5676L21.2866 10.6899Z"></path></svg>
                      :
                      <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.2866 10.6899L52.3166 26.8356C54.3402 27.8885 55.127 30.3824 54.0741 32.406C53.6826 33.1583 53.069 33.772 52.3166 34.1634L21.2866 50.3091C19.2631 51.362 16.7692 50.5752 15.7163 48.5517C15.41 47.9629 15.25 47.3089 15.25 46.6452V14.3538C15.25 12.0728 17.0992 10.2236 19.3802 10.2236C19.9491 10.2236 20.5108 10.3411 21.0305 10.5676L21.2866 10.6899Z"></path></svg>
                    }
                  </button>
                </LinkWithTooltip>
              </div>
            </div>
            <div className='videoControlsWrap' style={Connectapp === true && isAndroidIOSMobile === true ? { display: "none" } : { display: "block" }}>
              <div className='videoRangeWrap'>
                <input type='range' min={0} max={1} step="any"
                  value={loadProgress}
                  className='videoPreloadRange'
                />
                
                <input type='range' min={0} max={1} step="any"
                  value={played}
                  onChange={(e) => handleSeekChange(e)}
                  className='videoProgressRange'
                />
              </div>
              <div className='videoControl-bottomPanel'>
                <div className='videoControl-rightArea'>
                  <LinkWithTooltip tooltip={playing ? "Pause" : ReplayplayerRef.current ? "Reply" : "Play"} placement = "top" isFromConnectApp={Connectapp}>
                    <button onClick={() => togglePlayPause()} className='videoControl videoControl-playPush'>
                      {playing ?
                        <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 2a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H5ZM4 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4Zm9-2a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-2Zm-1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4Z" >
                          </path>
                        </svg>
                        :
                        ReplayplayerRef.current ?
                          <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 6.5v-3a.5.5 0 0 1 1 0v1.2a7.98 7.98 0 0 1 7.94-2.46A8 8 0 1 1 2 9.48a.5.5 0 1 1 1 .07A6.97 6.97 0 0 0 3 10a7 7 0 1 0 1.25-4H6a.5.5 0 0 1 0 1H3.5a.5.5 0 0 1-.5-.5Zm4.5 1.47c0-.93.98-1.54 1.81-1.12l4.04 2.03c.92.47.92 1.77 0 2.24l-4.04 2.03a1.25 1.25 0 0 1-1.81-1.12V7.97Zm1.36-.23a.25.25 0 0 0-.36.23v4.06c0 .19.2.31.36.23l4.04-2.04a.25.25 0 0 0 0-.44L8.86 7.74Z" ></path></svg>
                          :
                          <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.22 8.69a1.5 1.5 0 0 1 0 2.62l-10 5.5A1.5 1.5 0 0 1 5 15.5v-11A1.5 1.5 0 0 1 7.22 3.2l10 5.5Zm-.48 1.75a.5.5 0 0 0 0-.88l-10-5.5A.5.5 0 0 0 6 4.5v11c0 .38.4.62.74.44l10-5.5Z">
                            </path>
                          </svg>
                      }
                    </button>
                  </LinkWithTooltip>
                  <LinkWithTooltip tooltip="Skip backward 10 seconds" placement = "top" isFromConnectApp={Connectapp}>
                    <button onClick={() => skipBackwardForward(playerRef.current.getCurrentTime() - 10)} className='videoControl videoControl-backword'>
                      <svg fill="white" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 3.5a.5.5 0 1 1 1 0v2.2a8 8 0 0 1 13.16 1.75.5.5 0 1 1-.9.44 7 7 0 0 0-12-.89H7.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4Zm5 7a.5.5 0 0 0-.78-.42l-1.5 1a.5.5 0 0 0 .56.84l.72-.49v5.07a.5.5 0 0 0 1 0v-6Zm4.5-.5c-.93 0-1.6.44-2 1.13-.37.65-.5 1.5-.5 2.37 0 .86.13 1.72.5 2.37.4.7 1.07 1.13 2 1.13.94 0 1.6-.44 2-1.13.38-.65.5-1.5.5-2.37 0-.86-.12-1.72-.5-2.37a2.18 2.18 0 0 0-2-1.13ZM11 13.5c0-.8.13-1.44.37-1.87.23-.4.57-.63 1.13-.63.57 0 .9.23 1.13.63.25.43.37 1.08.37 1.87 0 .8-.12 1.44-.37 1.87-.22.4-.56.63-1.13.63-.56 0-.9-.23-1.13-.63A3.87 3.87 0 0 1 11 13.5Z" fill="white">
                        </path>
                      </svg>
                    </button>
                  </LinkWithTooltip>
                  <LinkWithTooltip tooltip="Skip forward 10 seconds" placement = "top" isFromConnectApp={Connectapp}>
                    <button onClick={() => skipBackwardForward(playerRef.current.getCurrentTime() + 10)} className='videoControl videoControl-forword'>
                      <svg fill="white" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3.5a.5.5 0 1 0-1 0v2.2A8 8 0 0 0 2.84 7.45a.5.5 0 1 0 .9.44 7 7 0 0 1 12-.89H12.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 .5-.5v-4Zm-6.5 7.63c.4-.7 1.07-1.13 2-1.13.94 0 1.6.44 2 1.13.37.65.5 1.5.5 2.37 0 .86-.13 1.72-.5 2.37-.4.7-1.06 1.13-2 1.13-.93 0-1.6-.44-2-1.13-.37-.65-.5-1.5-.5-2.37 0-.86.13-1.72.5-2.37Zm.87.5A3.87 3.87 0 0 0 11 13.5c0 .8.12 1.44.37 1.87.23.4.57.63 1.13.63.57 0 .9-.23 1.13-.63.25-.43.37-1.08.37-1.87 0-.8-.12-1.44-.37-1.87-.23-.4-.56-.63-1.13-.63-.56 0-.9.23-1.13.63ZM8 10.5a.5.5 0 0 0-.78-.42l-1.5 1a.5.5 0 1 0 .56.84l.72-.49v5.07a.5.5 0 0 0 1 0v-6Z" fill="white"></path>
                      </svg>
                    </button>
                  </LinkWithTooltip>
                  <div className='volumeWrap'
                    onMouseEnter={(e) => {
                      if (hasaudio) { e.currentTarget.children[0].style.display = "flex"; }
                    }}
                    onMouseLeave={(e) => {
                      if (hasaudio) { e.currentTarget.children[0].style.display = "none"; }
                    }}
                    onMouseUpCapture={(e) => {
                      if (hasaudio) { e.currentTarget.children[0].style.display = "flex"; }
                    }}
                  >
                    {hasaudio &&
                      <span className='volumeMainWrapRange'
                        onMouseLeave={(e) => {
                          e.target.style.display = "none";
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.display = "flex";
                          if (e.target.children[0]) {
                            e.target.children[0].style.display = "block";
                          }
                        }}>
                        <input
                          className='volumeWrapRange'
                          type='range'
                          min={0}
                          max={1}
                          step='any'
                          defaultValue={0}
                          value={muted ? 0 : volume}
                          onChange={handleVolumeChange}

                        />
                      </span>
                    }
                    <LinkWithTooltip tooltip={muted ? hasaudio ? "Unmute" : "This video has no sound" : "Mute"} placement="bottom" isFromConnectApp={Connectapp}>
                      <button onClick={toggleMute} className='videoControl videoControl-valume'>
                        {muted ?
                          <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.85 2.15a.5.5 0 1 0-.7.7L5.29 6H3.5C2.68 6 2 6.67 2 7.5v5c0 .83.68 1.5 1.5 1.5H6.1a.5.5 0 0 1 .34.13l3.88 3.6a1 1 0 0 0 1.68-.74V12.7l5.15 5.15a.5.5 0 0 0 .7-.7l-2.56-2.57L2.85 2.15ZM11 11.7V17l-3.88-3.6A1.5 1.5 0 0 0 6.1 13H3.5a.5.5 0 0 1-.5-.5v-5c0-.28.22-.5.5-.5h2.6l.18-.01L11 11.7ZM11 3v5.88l1 1V3a1 1 0 0 0-1.68-.74L7.24 5.12l.71.71L11 3.01Zm3.06 8.94.74.74a5.5 5.5 0 0 0-.04-5.43.5.5 0 1 0-.86.5 4.5 4.5 0 0 1 .16 4.19Zm1.84 1.84.72.72a8 8 0 0 0-.66-9.83.5.5 0 1 0-.74.66 7 7 0 0 1 .68 8.45Z" fill="currentColor">
                            </path>
                          </svg>
                          :
                          <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 3a1 1 0 0 0-1.68-.73l-3.88 3.6A.5.5 0 0 1 6.1 6H3.5C2.67 6 2 6.67 2 7.5v5c0 .83.67 1.5 1.5 1.5h2.6a.5.5 0 0 1 .34.13l3.88 3.6a1 1 0 0 0 1.68-.74V3.01ZM7.12 6.6 11 3v14l-3.88-3.6A1.5 1.5 0 0 0 6.1 13H3.5a.5.5 0 0 1-.5-.5v-5c0-.28.22-.5.5-.5h2.6c.38 0 .75-.14 1.02-.4Zm8.14-1.97a.5.5 0 0 1 .7.04 8 8 0 0 1 0 10.66.5.5 0 0 1-.74-.66 7 7 0 0 0 0-9.34.5.5 0 0 1 .04-.7Zm-1.18 8.3a.5.5 0 0 1-.18-.68 4.5 4.5 0 0 0 0-4.5.5.5 0 1 1 .86-.5 5.5 5.5 0 0 1 0 5.5.5.5 0 0 1-.68.18Z" >
                            </path>
                          </svg>
                        }
                      </button>
                    </LinkWithTooltip>
                  </div>
                  {(videoRange !== Infinity) && <div className='videoControl videoControl-timing'>{currentTime} / {duration}</div>}
                </div>
                <div className='videoControl-leftArea' >
                  <LinkWithTooltip tooltip="Playback speed" placement="left" isFromConnectApp={Connectapp}>
                    <Dropdown className='videoSpeed' onToggle={() => setIsOpen(!isOpen)}>
                      <Dropdown.Toggle variant="" id="">
                        {playbackRate}x
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {options.map((option) => (
                          <Dropdown.Item
                            key={option.value}
                            onClick={() => handlePlaybackRateChange(option.value)}
                            className={option.value === playbackRate ? 'selected videoSpeedoption' : 'videoSpeedoption'}
                          >
                            <i class="fa-duotone fa-check"></i> <span className='videoSpeedoptionValue'>{option.label}</span>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </LinkWithTooltip>
                  <LinkWithTooltip tooltip="Full screen" placement = "top" isFromConnectApp={Connectapp}>
                    <button ref={FullScreenButtonRef} onClick={(e) => toggleFullscreen(e)} className='videoControl videoControl-expand'>
                      <i class="fa-thin fa-expand"></i>

                    </button>
                  </LinkWithTooltip>
                  {/* <button onClick={() => toggleSmallScreen()} className='videoControl videoControl-minimize'>
                  <i class="fa-thin fa-browsers"></i>
                </button> */}
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <div className='inChatLoading' style={ready ? (Connectapp === true && isIOSMobile === false && canvasReady === false) ? { display: "block" } : { display: "none" } : { display: "block" }}>
        {faileToLoad ?
          <div className='loadtoFailText'>
            <h2>This browser or app can't play the video.</h2>
            <p>Something might be outdated or not supported. Try again using a different browser or app.</p>
          </div>
          :
          <div className='loadingWrap'>
            <span className='videoloader'></span>
            <span className='loaderText'>Loading...</span>
          </div>
        }
      </div>
    </div>
  );
};

const CanvasImage = ({ videoUrl , onReady}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Create video element dynamically
    const video = document.createElement('video');
    video.src = videoUrl;
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';

    const handleLoadedData = () => {
      if (video.readyState >= 2) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        onReady(true)
      }
    };

    video.addEventListener('loadeddata', handleLoadedData);
    video.load();

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
    };
  }, [videoUrl]);

  return <canvas ref={canvasRef} className='firstPlayImg'></canvas>;
};

export default VideoPlayer;
