import React from 'react'
import ReactDOM from 'react-dom'
import App from "../src/App";
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import store from './app/store'
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import $ from 'jquery';

let persistor = persistStore(store);

// Get a cookie value using document.cookie in index.js
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return '';
}
const themePreference = getCookie('ThemePreferance');

if (themePreference == 1) {
  $('html').attr('data-theme', 'dark');
}
else if (themePreference == 2) {
  $('html').attr('data-theme', '');
}
else {
  $('html').attr('data-theme', 'light');
}
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();