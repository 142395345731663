import React, { Fragment, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TokenGenerate, } from '../src/component/Slice/Token'
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../src/utils/AppInsights";
import Loader from './component/Common/Loader';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./app/App.scss";
import $ from "jquery";
import VagaroConnect from './Vagaroconnect/VagaroConnect'
import "./assets/style/darktheme.scss";
import { ChatProvider } from './Vagaroconnect/Store/ChatProvider';
import * as signalR from '@microsoft/signalr';
import { utils } from './utils/Utils';
import { StenCiledContaint } from './Vagaroconnect/Binding/StenciedData';
import { AsyncAjaxGet, HeaderDetail, SyncAjaxDelete } from './component/Common/AsyncAjaxServiceV2';
import { CommonMethods, LeftControlNames, ThunkApiEnum } from './Vagaroconnect/Binding/ChatBoatEnums';
import VagaroAI from './Vagaroconnect/setting/VagaroAI';
import { setActivate } from './component/Slice/BusinessSettings';
import { fetchTextMarketing, ThunkAPI } from './component/Common/AsyncThunk';
import { DeactiveAi, TriggerEnableTextMaketing } from './component/Slice/TextMarketingSlice';

const App = () => {
    const dispatch = useDispatch();
    const { generate_time, access_token } = useSelector((state) => state.Token);
    const [ShowFirst, setFirstShow] = useState(false);
    const [ShowFirstLoader, setShowFirstLoader] = useState(true);
    const [lastScroll, setlastScroll] = useState(0);
    const [newChat, setNewChat] = useState(false);
    const [isBusinessedtailPageCustomer, setBusinessedtailPageCustomer] = useState(false);
    const [isOnLine, setisOnLine] = useState(true);
    const [isIframLoad, setsIframLoad] = useState(false);
    const [initialData, setinitialData] = useState([]);
    const [isCustomerChat, setIsCustomerChat] = useState(false);
    const [defaultSize, setDefaultSize] = useState(0);
    const [isFromConnectApp, setIsFromConnectApp] = useState(false);
    const [isIOSMobile, setIsIOSMobile] = useState(false);
    const [isAndroidIOSMobile, setIsAndroidIOSMobile] = useState(false);
    const [device, setDevice] = useState("")
    const navigate = useNavigate();
    const isInternet = useRef(null);
    const listnerRef = useRef(true);
    const IframeRef = useRef({open: false});
    const [isIframeOpened, setIsIframeOpened] = useState(false);
    const [isLeftOption, setLeftOption] = useState(LeftControlNames.Customer);
    const [activeTabState, setactiveTabState] = useState(true);
    const [countryDateFormat, setcountryDateFormat] = useState([]);
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
    let isIpadold = /iPad/i.test(navigator.userAgent);
    let iosPhone = /iPhone /i.test(navigator.userAgent);
    let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);
    const UserAccess = useSelector((state) => state.Settings.UserAccess);
    const isConnectAi = useSelector((state) => state.Settings.Activate);
    const location = useLocation();
    useEffect(() => {
        GetToken();
    }, [access_token]);

    useEffect(() => {
        listeners();
        activeTab();
    }, []);

    useEffect(() => {
        IframeRef.current.open = isIframeOpened
    }, [isIframeOpened])


    useEffect(() => {
        isInternet.current = isOnLine;
        setShowFirstLoader(!isOnLine);
    }, [isOnLine]);
    window.beforeunloadcompleted = false;
    useEffect(() => {
        if (window.self === window.top) {
            /* if connect opens directly in window without frame */
            let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
            let isIpadold = /iPad/i.test(navigator.userAgent);
            let isIphone = /iPhone/i.test(navigator.userAgent);
            let isAndroid = /Android/i.test(navigator.userAgent);
            if (window.navigator.userAgent.indexOf("com.vagaro.iospro") > -1 || window.navigator.userAgent.indexOf("com.vagaro.androidpro") > -1) {
        }
            else if (isIpad || isIpadold || isIphone || isAndroid) {
                window.location.href = "https://vagaroprows3-2-5.vagaro.com/VagaroConnect.html";
            }
            else if (!window.navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && !window.navigator.userAgent.indexOf("com.vagaro.androidpro") > -1) {
                window.location.href = "https://www.vagaro.com/login?Type=FromConnect&RedirectPageURL=https://www.vagaro.com/merchants/calendar?connect=true";
            }
        }
        if (typeof window !== "undefined") {
            const handleBeforeUnload = (event) => {
                if (isCustomerChat) {
                    window.DeleteChannelDetail();
                }
            };
            window.addEventListener('beforeunload', handleBeforeUnload);

            // Cleanup function to remove the event listener
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };

        }
    }, []);

    const activeTab = useCallback(() => {
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                setactiveTabState(false);
                // tab is changed
            } else {
                setactiveTabState(true);
                // tab is active
            }
        });
        return () => {
            document.removeEventListener("visibilitychange", () => { });
        }
    }, []);


    const listeners = useCallback(() => {
        window.addEventListener("online", () => setisOnLine(true));
        window.addEventListener("offline", () => setisOnLine(false));
        return () => {
            window.removeEventListener("online", () => setisOnLine(true));
            window.removeEventListener("offline", () => setisOnLine(false));
        };
    }, []);

    const BusinessID =
        typeof initialData.ReactBId === "undefined" ||
            initialData.ReactBId == null ||
            initialData.ReactBId === 0 ||
            initialData.ReactBId === ""
            ? 'U2FsdGVkX1/JBDnJoKzE9hJGg9xOX3Hja0YdhbzJqlc='

            : initialData.ReactBId;

    const LoginUserID =
        typeof initialData.ReactUId === "undefined" ||
            initialData.ReactUId == null ||
            initialData.ReactUId === 0 ||
            initialData.ReactUId === ""
            ? 'U2FsdGVkX1+U4QViWR1VS/pNXT7dvGZBfMM0aN0BXXE='

            : initialData.ReactUId;


    const merchantId =
        typeof initialData.ReactV2BId === "undefined" ||
            initialData.ReactV2BId == null ||
            initialData.ReactV2BId === 0 ||
            initialData.ReactV2BId === ""

            ? 'U2FsdGVkX1-E1RkGah1wNwivIzTbjvM0frRYBw5Qlb8='

            : initialData.ReactV2BId;

    const LoginUserIDV2 =
        typeof initialData.ReactV2UId === "undefined" ||
            initialData.ReactV2UId == null ||
            initialData.ReactV2UId === 0 ||
            initialData.ReactV2UId === ""
            ? 'U2FsdGVkX1~a88grrkLHIgx5xn2xTiWoOY4~D9cTlTs='

            : initialData.ReactV2UId;

    const LoginUserIdHash =
        typeof initialData.LoginUserIdHash === "undefined" ||
            initialData.LoginUserIdHash == null ||
            initialData.LoginUserIdHash === 0 ||
            initialData.LoginUserIdHash === ""
            ? 'E2AE4BBD9C5016E4C4E9CCCD5BBF5DD0C1D1A1E6'

            : initialData.LoginUserIdHash;



    const ReactBusinessId =
        typeof initialData.ReactBusinessId === "undefined" ||
            initialData.ReactBusinessId == null ||
            initialData.ReactBusinessId === 0 ||
            initialData.ReactBusinessId === ""
            ? 5101605
            : parseInt(initialData.ReactBusinessId);
    const ReactUserID =
        typeof initialData.ReactUserID === "undefined" ||
            initialData.ReactUserID == null ||
            initialData.ReactUserID === 0 ||
            initialData.ReactUserID === ""
            ? 1309097568
            : parseInt(initialData.ReactUserID);

    const ReactShopOwnerId =
        typeof initialData.ReactShopOwnerId === "undefined" ||
            initialData.ReactShopOwnerId == null ||
            initialData.ReactShopOwnerId === 0 ||
            initialData.ReactShopOwnerId === ""
            ? 1309097585
            : initialData.ReactShopOwnerId;

    const ReactBusinessName =
        typeof initialData.ReactBusinessName === "undefined" ||
            initialData.ReactBusinessName == null ||
            initialData.ReactBusinessName === ""
            ? 'Jaimin Salon - Dev'
            : initialData.ReactBusinessName;

    const ReactFirstName =
        typeof initialData.ReactFirstName === "undefined" ||
            initialData.ReactFirstName == null ||
            initialData.ReactFirstName === ""
            ? 'Jack'
            : initialData.ReactFirstName;

    const ReactLastName =
        typeof initialData.ReactLastName === "undefined" ||
            initialData.ReactLastName == null ||
            initialData.ReactLastName === ""
            ? 'mehta'
            : initialData.ReactLastName;

    const ReactProfileImage =
        typeof initialData.ReactProfileImage === "undefined" ||
            initialData.ReactProfileImage == null
            ? '1309097568_5101605_$2024_03_06_07_28_20_0741.png'
            : initialData.ReactProfileImage == "user-male-img_155.png" || initialData.ReactProfileImage == "user-female-img_155.jpg" || initialData.ReactProfileImage == "user-male-img_155_new.png" || initialData.ReactProfileImage == "user-img-staff" || initialData.ReactProfileImage == "user-femail-img" ? "" : initialData.ReactProfileImage

    const ReactCdnUrl =
        typeof initialData.ReactCdnUrl === "undefined" ||
            initialData.ReactCdnUrl == null ||
            initialData.ReactCdnUrl === ""
            ? "https://d87d1c6150f9e163834b-81906ee575cd92591782e5779171783e.ssl.cf2.rackcdn.com/"
            : initialData.ReactCdnUrl;

    const ViewCustomer =
        typeof initialData.ViewCustomer === "undefined" ||
            initialData.ViewCustomer == null ||
            initialData.ViewCustomer === ""
            ? JSON.parse("True".toLowerCase())
            : JSON.parse(initialData.ViewCustomer.toLowerCase());

    const ModifyCustomer =
        typeof initialData.ModifyCustomer === "undefined" ||
            initialData.ModifyCustomer == null ||
            initialData.ModifyCustomer === ""
            ? JSON.parse("True".toLowerCase())
            : JSON.parse(initialData.ModifyCustomer.toLowerCase());

    const ViewEmployee =
        typeof initialData.ViewEmployee === "undefined" ||
            initialData.ViewEmployee == null ||
            initialData.ViewEmployee === ""
            ? JSON.parse("True".toLowerCase())
            : JSON.parse("True".toLowerCase());

    const ModifyEmployee =
        typeof initialData.ModifyEmployee === "undefined" ||
            initialData.ModifyEmployee == null ||
            initialData.ModifyEmployee === ""
            ? JSON.parse("True".toLowerCase())
            : JSON.parse("True".toLowerCase());

    const ReactBusinessImage =
        typeof initialData.ReactBusinessImage === "undefined" ||
            initialData.ReactBusinessImage == null ||
            initialData.ReactBusinessImage === ""
            ? ""
            : initialData.ReactBusinessImage.includes("Default") ? "" : initialData.ReactBusinessImage;

    const ManageCustomerViewRights =
        typeof initialData.ManageCustomerViewRights === "undefined" ||
            initialData.ManageCustomerViewRights == null ||
            initialData.ManageCustomerViewRights === ""
            ? JSON.parse("false".toLowerCase())
            : JSON.parse(initialData.ManageCustomerViewRights.toLowerCase());

    const ManageCustomerModifyRights =
        typeof initialData.ManageCustomerModifyRights === "undefined" ||
            initialData.ManageCustomerModifyRights == null ||
            initialData.ManageCustomerModifyRights === ""
            ? JSON.parse("false".toLowerCase())
            : JSON.parse(initialData.ManageCustomerModifyRights.toLowerCase());

    const ManageOtherPeopleCalendarViewRights =
        typeof initialData.ManageOtherPeopleCalendarViewRights === "undefined" ||
            initialData.ManageOtherPeopleCalendarViewRights == null ||
            initialData.ManageOtherPeopleCalendarViewRights === ""
            ? JSON.parse("true".toLowerCase())
            : JSON.parse(initialData.ManageOtherPeopleCalendarViewRights.toLowerCase());

    const ManageOtherPeopleCalendarModifyRights =
        typeof initialData.ManageOtherPeopleCalendarModifyRights === "undefined" ||
            initialData.ManageOtherPeopleCalendarModifyRights == null ||
            initialData.ManageOtherPeopleCalendarModifyRights === ""
            ? JSON.parse("true".toLowerCase())
            : JSON.parse(initialData.ManageOtherPeopleCalendarModifyRights.toLowerCase());

    const Domain =
        typeof initialData.Domain === "undefined" ||
            initialData.Domain == null ||
            initialData.Domain === ""
            ? "https://vagaro.com"
            : initialData.Domain;

    const isMultilocationBusiness =
        typeof initialData.isMultilocationBusiness === "undefined" ||
            initialData.isMultilocationBusiness == null ||
            initialData.isMultilocationBusiness === 0 ||
            initialData.isMultilocationBusiness === ""
            ? false
            : initialData.isMultilocationBusiness === "True" ? true : false;

    const ManageEmployeeViewRights =
        typeof initialData.ManageEmployeeViewRights === "undefined" ||
            initialData.ManageEmployeeViewRights == null ||
            initialData.ManageEmployeeViewRights === ""
            ? JSON.parse("false".toLowerCase())
            : JSON.parse(initialData.ManageEmployeeViewRights.toLowerCase());

    const ManageEmployeeModifyRights =
        typeof initialData.ManageEmployeeModifyRights === "undefined" ||
            initialData.ManageEmployeeModifyRights == null ||
            initialData.ManageEmployeeModifyRights === ""
            ? JSON.parse("false".toLowerCase())
            : JSON.parse(initialData.ManageEmployeeModifyRights.toLowerCase());

    const MerchantChannelId =
        typeof initialData.MerchantChannelId === "undefined" ||
            initialData.MerchantChannelId == null ||
            initialData.MerchantChannelId === ""
            ? ReactBusinessId
            : initialData.MerchantChannelId;

    const CountryID =
        typeof initialData.CountryID === "undefined" ||
            initialData.CountryID == null ||
            initialData.CountryID === ""
            ? JSON.parse(1)
            : JSON.parse(initialData.CountryID);

    const GetToken = async () => {
        if (typeof generate_time == "undefined" ||
            generate_time == null ||
            generate_time == "" || Date.parse(new Date()) > generate_time) {
            await dispatch(TokenGenerate());
        }
    }

    const ModifyFeatureSubscription =
        typeof initialData.ModifyFeatureSubscription === "undefined" ||
            initialData.ModifyFeatureSubscription == null ||
            initialData.ModifyFeatureSubscription === ""
            ? JSON.parse("false".toLowerCase())
            : JSON.parse(initialData.ModifyFeatureSubscription.toLowerCase());



    const VagaroAPIEnum = {
        GetCountryDateFormat: `merchants/dateformat`,
    };

    window.vagaroConnectMobilelistner = (m) => {
        switch (m.eventType) {
            case 'GetIntitalData':
                setinitialData(m.payload)
                setIsCustomerChat(m.payload.isCustomerChat);
                window.VagaroCoreAPIURLConnect = m.payload.VagaroCoreAPIURLConnect;
                window.VagaroCoreAPIURLV2 = m.payload.VagaroCoreAPIURLV2;
                window.VagaroConnectSignalRURL = m.payload.VagaroCoreAPIURLConnect;
                ThunkApiEnum.Merchant = m.payload.ReactV2BId;
                ThunkApiEnum.User = m.payload.ReactUId;
                HeaderDetail.encStaticMerchantid = m.payload.encStaticMerchantid;
                HeaderDetail.encStaticUserid = m.payload.encStaticUserid;
                HeaderDetail.ShopOwnerId = m.payload.ReactShopOwnerId;
                const existingLink = document.querySelector(`link[href="${m.payload.GlobalThemePath}"]`);
                if (!existingLink) {
                    let link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.href = m.payload.GlobalThemePath;
                    document.head.appendChild(link);
                }
                if (m.payload.conversationType !== undefined && m.payload.conversationType > 0) {
                    if (m.payload.conversationType === 1) {
                        setLeftOption(LeftControlNames.Customer);
                    } else if (m.payload.conversationType === 2) {
                        setLeftOption(LeftControlNames.Employee);
                    }
                }
                if (m.payload.isFromConnectApp === true) {
                    m.payload.TextMessagingModifyRights = "True";
                    if (m.payload.customerId !== undefined && m.payload.userIdHash !== undefined && m.payload.customerId !== "" && m.payload.userIdHash !== "") {
                        if (m.payload?.isChatForCustomer === false) {
                            setLeftOption(LeftControlNames.Employee);
                        }
                    }
                }
                setDevice(m.payload?.Device ? m.payload.Device : "");
                setDefaultSize(m.payload.defaultSize ? m.payload.defaultSize : 0);
                setIsFromConnectApp(m.payload.isFromConnectApp ? m.payload.isFromConnectApp : false);
                setIsAndroidIOSMobile(m.payload.isAndroidIOSMobile ? m.payload.isAndroidIOSMobile : false);
                setIsIOSMobile(m.payload.isIOSMobile ? m.payload.isIOSMobile : false);
                setsIframLoad(true);
                if (parseInt(m.payload.intialState ?? 0) > 0) {
                    setIsIframeOpened(true);
                }
                if (typeof window.onCkeditoerResize === 'function') {
                    window.onCkeditoerResize();
                }
                if (typeof utils.SetHeight === 'function') {
                    utils.SetHeight("App");
                }
                if (typeof window.UpsertChannelDetail === 'function' && m.payload.isCustomerChat) {
                    window.UpsertChannelDetail();
                }
                // if (typeof window.PushNotificationConversation === 'function' && m.payload.pushNotificationConversationId !== undefined && m.payload.pushNotificationConversationId !== "") {
                //     window.PushNotificationConversation(m.payload);
                // }
                // if (typeof window.openCustomerChat === 'function' && m.payload.isFromConnectApp === true) {
                //     if (m.payload.customerId !== undefined && m.payload.userIdHash !== undefined && m.payload.customerId !== "" && m.payload.userIdHash !== "") {
                //         window.openCustomerChat(m.payload);
                //     }
                // }
                if (m.payload.isTextmarketingEnable === true && m.payload.isAi !== undefined && m.payload.isAi === true) {
                    console.trace('TriggerEnableTextMaketing(true)')
                    dispatch(TriggerEnableTextMaketing(true));
                }
                if (m.payload.isAi !== undefined && m.payload.isAi === true && isFromConnectApp) {
                    console.trace('fetchTextMarketing()')
                    dispatch(fetchTextMarketing());
                }

                if (m.payload.isAi !== undefined && m.payload.isAi === true) {
                    navigate('/setting')
                    $("body").addClass('connectSettingPage');
                    } else {
                    $("body").removeClass('connectSettingPage');
                }               
                              
                break;
            case 'CallBackFromMobile':
                if (m.payload) {
                    window.setMobileData(JSON.stringify(m.payload));
                }
                break;

            case 'TextMarketingCallBack':
                window.TextMarketingCallBack();
                break;
            case 'upDateDetails':
                if (typeof window.UpdateDetails === 'function') {
                    window.UpdateDetails(JSON.stringify(m.payload));
                }
                break;
            case 'AppoinemtsPopupUpdte':
                if (typeof window.AppoinemtsPopupUpdte === 'function') {
                    window.AppoinemtsPopupUpdte(m.payload);
                }
                break;
            case 'addStyleVagaroconnect':
                let pstyle = m.payload;
                //$.ajax('/assets/WebsiteBuilder/wbtheme.css', { cache: true });
                var cssStyle = "";
                cssStyle += ":root{"
                cssStyle += "--wbFontFamily:" + pstyle.lblstylefontfamily + ";";
                cssStyle += "--wbFontColor:" + pstyle.lblstylecolor + ";";
                cssStyle += "--wbFontSize:" + pstyle.lblstylefontsize + ";";
                cssStyle += "--wbFontWeight:" + pstyle.lblstylefontweight + ";";
                cssStyle += "--wbBtnPrimaryTxtColor: " + pstyle.primarybuttoncolor + ";";
                cssStyle += "--wbBtnPrimaryBgColor: " + pstyle.primarybuttonbackgroundcolor + ";";
                cssStyle += "--wbBtnPrimaryBdrColor: ''; ";
                cssStyle += "--wbBtnPrimaryRadius:" + pstyle.primarybuttonborderradius + ";";
                cssStyle += "--wbBtnPrimaryShadow:" + pstyle.primarybuttonboxshadow + ";";
                cssStyle += "--wbBtnPrimaryTxtColorHover: ''; ";
                cssStyle += "--wbBtnPrimaryBgColorHover:" + pstyle.primarybuttonHoverbackgroundcolor + ";";
                cssStyle += "--wbBtnPrimaryBdrColorHover: ''; ";
                cssStyle += "--wbBtnSecondaryTxtColor: " + pstyle.secondorybuttonbuttoncolor + ";";
                cssStyle += "--wbBtnSecondaryBgColor: " + pstyle.secondorybuttonbuttonbackgroundcolor + ";";
                cssStyle += "--wbBtnSecondaryBdrColor: '' ;";
                cssStyle += "--wbBtnSecondaryRadius:" + pstyle.secondorybuttonbuttonborderradius + ";";
                cssStyle += "--wbBtnSecondaryShadow:" + pstyle.secondorybuttonbuttonboxshadow + ";";
                cssStyle += "--wbBtnSecondaryTxtColorHover: '' ;";
                cssStyle += "--wbBtnSecondaryBgColorHover:" + pstyle.secondorybuttonbuttonHoverbackgroundcolor + ";";
                cssStyle += "--wbBtnSecondaryBdrColorHover: '' ;";
                cssStyle += "--wbFormInputColor: '' ;";
                cssStyle += "--wbFormInputColorFocus: '' ;";
                cssStyle += "--wbStarColor: " + pstyle.btnratingcolor + ";";
                cssStyle += "--wbBodyBg: " + pstyle.glbbackgroundcolor + ";";
                cssStyle += "--glbHeadFont:" + pstyle.glbHeadingFonts + ";";
                cssStyle += "}";
                cssStyle += 'html, body {font-family: var(--wbFontFamily)!important;background-color:var(--wbBodyBg)}.shoppingcart .modal-body, .shoppingcart .modal-content{background-color:var(--wbBodyBg);}.popupwidth542{max-width:100%}.shoppingcart>.popupwidth542.zs-fullwithcc .modal-content{padding:0}.popupwidth542 .modal-title, .shoppingcart ul.list-inline li>label{font-family:var(--glbHeadFont)!important}body .popupwidth542 .cciframe-addresswithcountry .cc-detail-address-input .select2-container-multi .select2-choices .select2-search-field input.select2-input{font-family: var(--wbFontFamily)!important;}.popupwidth542 .modal-footer .btn-primary{background-color: var(--wbBtnPrimaryBgColor)!important;border-color: transparent!important;color: var(--wbBtnPrimaryTxtColor)!important;}.popupwidth542 .modal-footer .btn-primary:hover{background-color: var(--wbBtnPrimaryBgColorHover)!important;border-color: transparent!important;color: var(--wbBtnPrimaryTxtColor)!important;}.popupwidth542 .modal-footer .btn-default{background-color: var(--wbBtnSecondaryBgColor)!important;border-color: transparent!important;color: var(--wbBtnSecondaryTxtColor)!important;}.popupwidth542 .modal-footer .btn-default:hover{background-color: var(--wbBtnSecondaryBgColorHover)!important;border-color: transparent!important;color: var(--wbBtnSecondaryTxtColor)!important;}';
                //$("#hdnBuilderFrameCSS").val(cssStyle);
                cssStyle += ".icon-plus-img:before {content:'\\f067'}";
                var fontUrl = "<link href='https://fonts.googleapis.com/css2?family=" + pstyle.lblstylefontfamily + "' rel='stylesheet'>";
                //var themeCSSfile = "<link href='/assets/WebsiteBuilder/wbtheme.css?v=9' rel='stylesheet'>";
                $('head').append(fontUrl);
                var head = document.head || document.getElementsByTagName('head')[0];
                let style = document.createElement('style');
                style.type = 'text/css';
                if (style.styleSheet) {
                    style.styleSheet.cssText = cssStyle;
                } else {
                    style.appendChild(document.createTextNode(cssStyle));
                }
                head.appendChild(style);
                //$('body').append(themeCSSfile);
                // iframeParent = parentID;
                // WBBusinessID = businessID;
                // wbc._postMessage('{"eventType": "iframeLoaded","parentID": "' + iframeParent + '"}');
                $("body,html").addClass("websitebuilder-loaded")
                break;
            case 'OpenCustomerChatCallback':
                if (m.payload) {
                    window.openCustomerChat(m.payload);
                }
                break;
            case 'customerNumberadded':
                if (typeof window.customerNumberadded === 'function') {
                    window.customerNumberadded();
                }
                break;
            case 'textmarketingactivefirstime':
                if (typeof window.activeTextmarketingPlan === 'function') {
                    window.activeTextmarketingPlan();
                    if (dispatch) {
                        ThunkAPI(dispatch, ThunkApiEnum.TextMarketing);
                    }
                }
                break;
            case 'ClearLocalStorage':
                localStorage.removeItem("CurrentConversation");
                break;
            case 'GetAILastModifiedDetails':
                document.getElementById('dvLastModified2010').innerHTML = m.payload;
                break;
            case 'TextMarketingCloseBtn':
                if (m.payload.isTextmarketingEnable == true) {
                    if (UserAccess) {
                        if (location.pathname.includes('setting') && isConnectAi === false) {
                            if (dispatch) {
                                dispatch(DeactiveAi(true));
                                dispatch(TriggerEnableTextMaketing(true));
                                dispatch(fetchTextMarketing());
                                dispatch(setActivate(true));
                                ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting);
                            }
                        }
                    }
                }
                break;
            case 'CloseConnectFrame':
                utils.EventCall("ChatBoxCloseClick")
                break;
            default:

                break;
        }
    }
    window.vagaroConnectlistner = (e) => {
        if (e != null && e != 'undefined' && e.data != null && e.data != 'undefined') {
            if (typeof e.data == 'undefined')
                return;

            var m = '';
            if (typeof e.originalEvent != 'undefined' && typeof e.originalEvent.data != 'undefined') {
                try {
                    m = JSON.parse(e.data || e.originalEvent.data);
                } catch (e) {
                }
            }
            else {
                try {
                    m = JSON.parse(e.data);
                } catch (e) {
                }
            }
            window.vagaroConnectMobilelistner(m);
        }
    }

    // $("body").on("touchstart", function (event) {
    //     console.log("touch start "+ event.touches.length);
    //     console.log(event.target.className);
    //     if (event.target.className === "image-gallery-image") {
    //         if (event.touches.length === 1) {
    //             window.gallerytouchposition = event.touches[0].clientY;
    //         }
    //         else {
    //             window.gallerytouchposition = 0;
    //         }
    //     }
    //     else if (event.touches.length > 1) {
    //         event.stopPropagation();
    //         event.preventDefault();
    //     }
    // });

    // $("body").on("touchend", function (event) {
    //     console.log("touch end "+ event.touches.length);
    //     console.log(event.target.className);
    //     if (event.target.className === "image-gallery-image") {
    //         if (window.gallerytouchposition > 0) {
    //             if (!isAndroidIOSMobile && (window.gallerytouchposition + 160 < event.changedTouches[0].clientY)) {
    //                 window.gallerytouchposition = 0;
    //                 window.closeGalleryByTouchDown();
    //             }
    //         }
    //     }
    // });

    //Add By hariom Baghel
    window.isResize = true;
    function handleResize() {
        resizeContentWrap(false);
    }
    window.addEventListener('resize', handleResize);
    window.visualViewport.addEventListener('resize', () => {
        var windowHeight = $(window).height();
        var VisualHeight = window.visualViewport.height;
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);

        if (isIpad || isIpadold) {
            if (window.isResize) {
                window.isResize = false;
                setTimeout(function () {
                    if (windowHeight != VisualHeight) {
                        $("body").addClass('keywordopen')
                        $("body,html").height(VisualHeight)

                        var contentHeight = VisualHeight;
                        if (!window.location.href.includes("/setting")) {
                            $(".iosmobiledevice .keywordopen.vcTab .vConnectMainWrap").height(contentHeight);
                            $("html").scrollTop(0);
                        }

                    } else {
                        $("body").removeClass('keywordopen')
                        $("body,html").height("100%")
                        $(".iosmobiledevice .vcTab .vConnectMainWrap").height("100%");
                    }
                }, 100);
                setTimeout(() => {
                    window.isResize = true;
                }, 500);
            }
        }
    });
    resizeContentWrap(false);
    var isSafari = !!window.GestureEvent
    if (isSafari == true) {
        $("html").addClass('iosmobiledevice');
    }
    window.visualViewport.addEventListener('resize', event => resizeContentWrap(false));
    window.visualViewport.addEventListener('scroll', event => resizeContentWrap(true));

    function resizeContent() {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        window.isResize = false;
        var windowHeight = $(window).height();

        var VisualHeight = window.visualViewport.height;
        var messagesAreaheader;
        if ($(".messagesArea-header ").length) {
            messagesAreaheader = $(".messagesArea-header ").outerHeight(true);
        } else {
            messagesAreaheader = 0;
        }
        var messagesAreafooter;
        if ($(".messagesArea-footer").length) {
            messagesAreafooter = $(".messagesArea-footer").outerHeight(true);
        } else {
            messagesAreafooter = 0;
        }

        var msgheader;
        if ($(".msg-header").length) {
            msgheader = $(".msg-header").outerHeight(true);
        } else {
            msgheader = 0;
        }



        var rightsidenav;
        if ($(".rightSideOnly-navBlock").length) {
            rightsidenav = $(".rightSideOnly-navBlock").outerHeight(true);
        } else {
            rightsidenav = 0;
        }
        var chatlistsearchwrap;
        if ($(".chatListBlock-searchWrap").length) {
            chatlistsearchwrap = $(".chatListBlock-searchWrap").outerHeight(true);
        } else {
            chatlistsearchwrap = 0;
        }

        if (windowHeight != VisualHeight) {
            if (isIpad || isIpadold) {
                $("html").scrollTop(0);
            }
            else {
                $("body").addClass('keywordopen')
                $("body,html").height(VisualHeight)
                var contentHeight = VisualHeight - messagesAreaheader - messagesAreafooter - msgheader - rightsidenav - chatlistsearchwrap;
                $(".iosmobiledevice .keywordopen.ios-scroll-height .chatList").height(contentHeight);
                $(".iosmobiledevice .keywordopen.ios-scroll-height .employee-multilocation-select .select2-results ul").height(contentHeight);
                $(".iosmobiledevice .keywordopen.ios-scroll-height .messagesArea-middle").height(contentHeight);
                $(".iosmobiledevice .keywordopen.ios-scroll-height .chatListBlock .employeeSelectWrap").height(contentHeight);
                $(".iosmobiledevice .keywordopen.ios-scroll-height .bottom-content-wrap").height(contentHeight);
                $(".iosmobiledevice .keywordopen.ios-scroll-height .welcomeScreen-Blank .employeeSelectWrap").height(contentHeight);
                $("html.mobilefull-height").scrollTop(0);



            }

        } else {
            $("body").removeClass('keywordopen')
            setTimeout(() => {
                $("body,html").height("100%")
                $(".iosmobiledevice .ios-scroll-height .chatList").height("inheri");
                $(".iosmobiledevice .ios-scroll-height .employee-multilocation-select .select2-results ul").height("auto");
                // $(".iosmobiledevice .ios-scroll-height .messagesArea-middle").height("initial");

                $(".iosmobiledevice .ios-scroll-height .bottom-content-wrap").height("auto");
                $(".iosmobiledevice .ios-scroll-height .employeeSelectWrap").height("auto");
                $(".iosmobiledevice .ios-scroll-height .welcomeScreen-Blank .employeeSelectWrap").height("auto");
            }, 60);

        }

    }

    function resizeContentWrap(scroll) {
        var isSafari = !!window.GestureEvent
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        if (isFromConnectApp == true && isIOSMobile == true && isSafari == true && window.isResize == true && isIpad == false && isIpadold == false) {
            window.isResize = false;
            setTimeout(function () {
                resizeContent();
            }, 100);
            setTimeout(() => {
                window.isResize = true;
            }, 500);
        }
        else {
            if (isFromConnectApp == true && isIOSMobile == false && window.isResize == true && (isIpad == true || isIpadold == true)) {
                window.isResize = false;
                setTimeout(function () {
                    resizeContent();
                }, 100);
                setTimeout(() => {
                    window.isResize = true;
                }, 500);
            }
        }

    }

    useEffect(() => {
        if (listnerRef.current) {
            window.addEventListener('message', window.vagaroConnectlistner, false);
            listnerRef.current = false;
            if (isTablet) {
                CommonMethods.AddClassToBody("vcAndroidTab")
            }
            if (isFromConnectApp && (isIpad || isIpadold || iosPhone)) {
                if (window.history.length > 1) {
                    window.history.go(-window.history.length + 1);
                    setTimeout(() => {
                        window.history.replaceState(null, '', window.location.origin);
                    }, 100);
                }
            }
        }

    }, []);


    useEffect(() => {
        if (isIframLoad) {
            if (isFromConnectApp) {
                if (isAndroidIOSMobile) {
                    $("body").addClass('vcMobile');
                    $("body").addClass('ios-scroll-height');
                    $("html").addClass('mobilefull-height');
                } else {
                    if ((isIpad || isIpadold || isTablet) && defaultSize !== 1) {
                        $("body").addClass('vcTab');
                    }
                    $("html").removeClass('mobilefull-height');
                }
                $("body").removeClass('customerChatWindow-mobile')
                // var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeCustomer-mobile' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                // window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
            } else {
                $("body").removeClass('vcMobile');
                $("body").removeClass('ios-scroll-height');
                $("body").removeClass('vcTab');
                $("html").removeClass('mobilefull-height');
                if (device.toLocaleLowerCase().indexOf("ipad") >= 0) {
                    $("body").addClass('vcTab-browser');
                    if (navigator.userAgent.toLowerCase().indexOf("vagaroapp") > -1) {
                        $("body").addClass('vcTab-vagaroapp');
                    }
                }
                if (isTablet) {
                    $("body").addClass('vcTab-customer');
                }
                if (isBusinessedtailPageCustomer && ((navigator.userAgent.indexOf("Android") > -1 && navigator.userAgent.indexOf("Mobile") > -1) || navigator.userAgent.indexOf("Mobile") > -1 || navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || (navigator.userAgent.indexOf("com.vagaro.iospro") > -1 && navigator.userAgent.indexOf("iPhone") > -1))) {
                    $("body").addClass('customerChatWindow-mobile')
                    var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeCustomer-mobile' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                    window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
                } else {
                    var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'vc-iframeCustomer-mobile' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                    window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
                    $("body").removeClass('customerChatWindow-mobile')
                }
            }
        }

    }, [isBusinessedtailPageCustomer, isIframLoad, isFromConnectApp, isAndroidIOSMobile])

    useEffect(() => {
        if (isIframLoad === true && initialData !== undefined && initialData !== null && initialData !== "") {
            // This Use For Orientation Changes For isIpad/isIpadold/isTablet
            const DoChage = (event) => {
                if (isTablet && !event.matches && window.InputElemntsFocued && window.InputElemntsFocued === true) {
                    return false;
                }
                return true;
            }

            const handleOrientationChange = (event) => {
                if (DoChage(event)) {
                    if (event.matches) {
                        $("body").removeClass('vcTab');
                        $("body").addClass('vcTab-portrait');
                        $("body").addClass('ios-scroll-height');
                        CommonMethods.RemoveElementbyClass("select2-container");
                        // utils.CallBackGivenToMobileSelectTab(1)
                        if (event.target?.firstLoad && event.target.firstLoad === true) {
                            setIsAndroidIOSMobile(true)
                            setDefaultSize(1);
                        } else {
                            setTimeout(() => {
                                setIsAndroidIOSMobile(true)
                                setDefaultSize(1);
                                event.firstLoad = true;
                            }, 150);
                        }

                    } else {
                        $("body").removeClass('vcMobile');
                        $("body").addClass('ios-scroll-height');
                        $("body").removeClass('vcTab-portrait');
                        $("body").addClass('vcTab');
                        setIsAndroidIOSMobile(false)
                        setDefaultSize(0);
                        if (window.OnResizeSelectCustomer) {
                            window.OnResizeSelectCustomer();
                        }
                    }
                    window.PrevOrientation = event.matches ? "landscape" : "portrait";
                    window.PrevChnageContainerClose = false;
                }

            };
            if (isFromConnectApp && (isIpad || isIpadold || isTablet)) {
                let orientationQuery = window.matchMedia('(orientation: portrait)');
                handleOrientationChange(orientationQuery);
                orientationQuery.addListener(handleOrientationChange);
                return () => {
                    orientationQuery.removeListener(handleOrientationChange);
                };
            }
        }
    }, [isFromConnectApp, isIframLoad, initialData]);

    const GetCountryDateFormat = async () => {
        await AsyncAjaxGet(
            VagaroAPIEnum.GetCountryDateFormat,
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.Data != null) {
                        setcountryDateFormat(data.data.Data);
                        utils.setCountryDateFormat(data.data.Data);
                    }

                } catch (error) {
                    utils.appInsightTrack(
                        ReactBusinessId,
                        ReactUserID,
                        "Date Format",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    ReactBusinessId,
                    ReactUserID,
                    "Date Format",
                    "",
                    data
                );
            }, false
            //, cancelationToken?.token
        );
    }

    useEffect(() => {
        if (countryDateFormat == null || countryDateFormat.length == 0) {
            GetCountryDateFormat();
        }
    }, [countryDateFormat]);


    return (access_token != undefined && access_token != null && access_token != "" && isIframLoad === true && initialData !== undefined && initialData !== null && initialData !== "" ?
        <AppInsightsContext.Provider value={reactPlugin}>
             {ShowFirstLoader && ShowFirst && <div id='ShowFirstLoader' className="loaderNew"><div className="loader-imgNew"></div></div>}
            <ChatProvider newChat={newChat} IframeRef={IframeRef} isCustomerChat={isCustomerChat} initialData={initialData}>
                <Suspense fallback={<Loader isgrid={false} />}>
                    <Fragment>
                        <VagaroConnect
                            device={device}
                            isFromConnectApp={isFromConnectApp}
                            setBusinessedtailPageCustomer={setBusinessedtailPageCustomer}
                            BusinessID={BusinessID}
                            LoginUserID={LoginUserID}
                            merchantId={merchantId}
                            LoginUserIDV2={LoginUserIDV2}
                            ReactBusinessId={ReactBusinessId}
                            ReactUserID={ReactUserID}
                            access_token={access_token}
                            ReactBusinessName={ReactBusinessName}
                            ReactFirstName={ReactFirstName}
                            ReactLastName={ReactLastName}
                            ReactProfileImage={ReactProfileImage}
                            ReactCdnUrl={ReactCdnUrl}
                            LoginUserIdHash={LoginUserIdHash}
                            setFirstShow={setFirstShow}
                            ShowFirst={ShowFirst}
                            // ScreenForMobile={ScreenForMobile.current.matches}
                            ModifyCustomer={ModifyCustomer}
                            ModifyEmployee={ModifyEmployee}
                            ViewCustomer={ViewCustomer}
                            ViewEmployee={ViewEmployee}
                            setShowFirstLoader={setShowFirstLoader}
                            ReactBusinessImage={ReactBusinessImage}
                            ManageCustomerViewRights={ManageCustomerViewRights}
                            ManageCustomerModifyRights={ManageCustomerModifyRights}
                            Domain={Domain}
                            FullName={ReactFirstName + " " + ReactLastName}
                            lastScroll={lastScroll}
                            setlastScroll={setlastScroll}
                            defaultSize={defaultSize}
                            newChat={newChat}
                            setNewChat={setNewChat}
                            ManageOtherPeopleCalendarModifyRights={ManageOtherPeopleCalendarModifyRights}
                            ManageOtherPeopleCalendarViewRights={ManageOtherPeopleCalendarViewRights}
                            isIOSMobile={isIOSMobile}
                            isAndroidIOSMobile={isAndroidIOSMobile}
                            isMultilocationBusiness={isMultilocationBusiness}
                            isInternet={isInternet}
                            isCustomerChat={isCustomerChat}
                            isIframeOpened={isIframeOpened}
                            setIsIframeOpened={setIsIframeOpened}
                            ManageEmployeeViewRights={ManageEmployeeViewRights}
                            ManageEmployeeModifyRights={ManageEmployeeModifyRights}
                            isIframLoad={isIframLoad}
                            isLeftOption={isLeftOption}
                            setLeftOption={setLeftOption}
                            initialData={initialData}
                            activeTabState={activeTabState}
                            MerchantChannelId={MerchantChannelId}
                            CountryID={CountryID}
                            ModifyFeatureSubscription={ModifyFeatureSubscription}
                        />
                    </Fragment>
                </Suspense>
            </ChatProvider>
        </AppInsightsContext.Provider>
        :
        <StenCiledContaint />
    );
}

export default App;