import React from 'react'
import { AsyncAjaxPost } from '../../component/Common/AsyncAjaxServiceV2';
import useGenerateToken from '../../component/Hooks/useGenerateToken';
import { useDispatch } from 'react-redux';
import { useChatContext } from '../Store/ChatProvider';
import { utils } from '../../utils/Utils';
import $ from 'jquery'

const TakeControl = (props) => {

    const { isCustomerChat } = props;
    const { CommonMethod, setAIControl } = useChatContext();    
    const dispatch = useDispatch();
    const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');

    const VagaroAPIEnum = {
        customerTakeControl: `connect/${props.merchantId}/takeaicontrol`,
    }

    const CustomerTakeControl = async () => {
        var Rights = CommonMethod.CheckModifyRights() || false;
        const accesstoken = await useGenerateToken(dispatch);
        if ("Customers" === ActiveLeftOption && Rights === false && !isCustomerChat) {
            utils.ShowAlert("Alert", "You Don't Have Rights", "Close");
            return;
        }
        var RequestData = props.GetRequest(false);
        setAIControl(false);
        await AsyncAjaxPost(
            VagaroAPIEnum.customerTakeControl,
            RequestData,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data === true) {

                    }
                    else {
                        console.log(data);
                    }
                } catch (error) {
                    console.log(data);
                }
            },
            function OnError(data) {
                console.log(data)
            },
            true
        );
    }

    return (
        <div className='controlaiWrap'>
            <div className='controlaiWrapBlock'>
                <div className='controlaiWrapBlock-textIconWrap'>
                    <div className='controlaiWrapBlock-iconWrap'>
                        <i class="fa-regular fa-sparkles"></i>
                    </div>
                    <div className='controlaiWrapBlock-text'>Vagaro AI is active.</div>
                </div>

                <div className='controlaiWrapBlock-button'>
                    <a class="btn btn-secondary vcgradientbtn vcsecondary-btn" href={false} onClick={() => CustomerTakeControl()}>
                        <div class="vcsecondaryInner">Take Control</div>
                    </a>
                </div>
            </div>
        </div>)
}

export default TakeControl