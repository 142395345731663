import store from '../../app/store'
import { AjaxGenerateToken } from '../Common/AsyncAjaxServiceV2';
import { AssignToken } from "../Slice/Token";

const VagaroAPIEnum = {
    updateToken: "Token/generatetoken"
};

const useGenerateToken = async (useDispatch) => {
    const dispatch = useDispatch;
    const { generate_time, access_token } = store.getState().Token;
    const Requestdata = {};
    if (typeof generate_time == "undefined" ||
        generate_time == null ||
        generate_time == "" || Date.parse(new Date()) > generate_time) {
        await AjaxGenerateToken(
            VagaroAPIEnum.updateToken,
            null,
            function onSuccess(data) {
                const token = data?.data?.access_token;
                const expiresIn = data?.data?.expires_in;
                if (token && expiresIn) {
                    Requestdata.access_token = token;
                    Requestdata.expires_in = expiresIn;
                    Requestdata.token_type = data?.data?.token_type;
                    Requestdata.scope = data?.data?.scope;
                    dispatch(AssignToken(Requestdata));
                }
                else {
                    console.warn("Failed to update access token");
                }
            },
            function OnError(data) {
                console.warn("Failed to update access token");
            }
        )
    }
    else {
        Requestdata.access_token = access_token;
    }
    return Requestdata.access_token;
};

export default useGenerateToken;