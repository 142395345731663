import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AjaxGenerateToken } from '../Common/AsyncAjaxServiceV2';


let Token = [];
const VagaroAPIEnum = {
    GenerateToken: "token/generatetoken"
};
export const TokenGenerate = createAsyncThunk(
    'PostGenerateToken',
    async (Request, thunkApi) => {
        await AjaxGenerateToken(
            VagaroAPIEnum.GenerateToken,
            Request,
            function onSuccess(data) {
                if (data != null && data.data != null) {
                    Token = data.data;
                }
                else {
                    return thunkApi.rejectWithValue([]);
                }
            },
            function OnError() {
                return thunkApi.rejectWithValue([]);
            }
        );
        return thunkApi.fulfillWithValue(Token);
    }
)

export const TokenSlice = createSlice({
    name: 'Token',
    initialState: {
        access_token: "",
        expires_in: "",
        token_type: "",
        scope: "",
        generateTime: new Date().setMinutes(new Date().getMinutes() - 45)
    },
    reducers: {        
        AssignToken: (state, action) => {
            state.access_token = action.payload.access_token;
            state.expires_in = action.payload.expires_in;
            state.token_type = action.payload.token_type;
            state.scope = action.payload.scope;
            state.generate_time = new Date().setMinutes(new Date().getMinutes() + 45);
        }
    },
    extraReducers: {
        [TokenGenerate.fulfilled]: (state, action) => {
            if (typeof action.payload !== undefined && action.payload !== null) {
                state.access_token = action.payload.access_token;
                state.expires_in = action.payload.expires_in;
                state.token_type = action.payload.token_type;
                state.scope = action.payload.scope;
                state.generate_time = new Date().setMinutes(new Date().getMinutes() + 45);
            }
        }
    }
})


export const {
    AssignToken
} = TokenSlice.actions

export default TokenSlice.reducer;
