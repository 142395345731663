import { StatusEnum } from "../../Vagaroconnect/Binding/ChatBoatEnums";
import { fetchTextMarketing } from "../Common/AsyncThunk";

export const ReducerTextMarketing = {
    TriggerEnableTextMaketing: (state, action) => {
        var TextMarketingEnable = action.payload
        if (TextMarketingEnable) {
            state.TriggerTextMarketingEnable = true;
        } else {
            state.TriggerTextMarketingEnable = false;
        }
    },
    DeactiveAi: (state, action) => {
        var DeactiveAiSetting = action.payload
        if (DeactiveAiSetting) {
            state.DeactiveAi = true;
        } else {
            state.DeactiveAi = false;
        }
    },
};
export const ExtraReducerTextMarketing = (builder) => {
    builder
        .addCase(fetchTextMarketing.pending, (state) => {
            state.Status = StatusEnum.Loading;
        })
        .addCase(fetchTextMarketing.fulfilled, (state, action) => {
            state.Status = StatusEnum.Done;
            if (typeof action.payload.data !== 'undefined') {
                const { usage, planUsageLimit, messengerUsage, activeSMSForMessenger, fromTextMessengerPhone, numberStatus, planName, countryId, overLimitRateInCentsText } = action.payload.data;
                state.TextMarketingEnable = true;
                state.Usage = usage;
                state.PlanUsageLimit = planUsageLimit ? planUsageLimit : null;
                state.MessengerUsage = messengerUsage ? messengerUsage : null;
                state.ActiveSMSForMessenger = activeSMSForMessenger ? activeSMSForMessenger : null;
                state.FromTextMessengerPhone = fromTextMessengerPhone ? fromTextMessengerPhone : null;
                state.NumberStatus = numberStatus ? numberStatus : null;
                state.PlanName = planName ? planName : null;
                state.CountryId = countryId ? countryId : null;
                state.OverLimitRateInCentsText = overLimitRateInCentsText ? overLimitRateInCentsText : null;
                var percentage = usage && planUsageLimit ? ((usage * 100) / planUsageLimit).toFixed(2) : null;
                var variant = "success";
                if (percentage >= 0 && percentage <= 90) {
                    variant = "success";
                }
                else if (percentage > 90 && percentage < 100) {
                    variant = "warning";
                }
                else if (percentage >= 100) {
                    variant = "danger";
                }
                state.Percentage = percentage;
                state.Variant = variant;
            } else {
                state.TextMarketingEnable = false
                state.Usage = null
                state.PlanUsageLimit = null
                state.MessengerUsage = null
                state.ActiveSMSForMessenger = null
                state.FromTextMessengerPhone = null
                state.NumberStatus = null
                state.PlanName = null
                state.CountryId = null
                state.OverLimitRateInCentsText = null
                state.Percentage = null
                state.Variant = null
            }
        })
};