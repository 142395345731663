import React from 'react';

const VCard = ({ reactBusinessName }) => {
    return (
        <div className="v-card">
            <div className='vcCard-welcomeText'>{`👋 Welcome to ${reactBusinessName}. Save our contact card and message us if you have any questions.`}</div>
            <div className='vcCard-footer'>
                <div className='vcCard-title'>
                    <div className='vcCard-title_name'>{reactBusinessName}</div>
                </div>
                <div className='vcCard-iconWrap'>
                    <i class="fa-solid fa-address-card"></i>
                </div>
            </div>
        </div>
    );
}

export default VCard;
