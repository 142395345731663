import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";

const VGPopUp = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {isStatic = false} = props; 

  const { isLoading } = props;

  useEffect(() => {
    if (props.isRemoveTabModalIndex) {
      let $allActiveBootboxesModals = $('.modal');
      $allActiveBootboxesModals.each(function (index, modal) {
        let $modal = $(modal);
        $modal.removeAttr('tabindex');
      });
    }
  }, []);

  return (
    <Modal
      backdrop={isStatic ? true : "static"}
      id={props.id}
      show={props.show}
      onHide={props.onHide}
      dialogClassName={props.dialogClassName}
      size={props.size != undefined && props.size != null && props.size != "" ? props.size : " lg"}
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
        {props.description}

      </Modal.Header>
      <Modal.Body onScroll={props.handleScroll}>
        {isLoading && (
          <div className="loaderNew">
            <div className="loader-imgNew"></div>
          </div>
        )}
        {props.body}
      </Modal.Body>
      {
        props.isShowFooter &&
        <Modal.Footer>
          {props.footer}</Modal.Footer>
      }
    </Modal>
  );
};
export default VGPopUp;
