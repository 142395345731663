import { createSlice } from '@reduxjs/toolkit';
import { StatusEnum } from '../../Vagaroconnect/Binding/ChatBoatEnums';
import { BusinessSetting, ExtraReducersSettings } from '../Reducers/BusinessSettingReducers';

const BusinessSettingSlice = createSlice({
  name: 'Settings',
  initialState: {
    Status: StatusEnum.Idle,
    Activate: false,
    UserAccess: true,
    Pause: false,
    AISettings: 1,
    CustomHoursValidation: {},
    businessoffhours:[],
    currencySymbol : "$",
    aiConnectPrice : 0.00,
    CustomHours: [
      {
        dayID: 2,
        name: "Monday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      },
      {
        dayID: 3,
        name: "Tuesday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      },
      {
        dayID: 4,
        name: "Wednesday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      },
      {
        dayID: 5,
        name: "Thursday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      },
      {
        dayID: 6,
        name: "Friday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      },
      {
        dayID: 7,
        name: "Saturday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      },
      {
        dayID: 1,
        name: "Sunday",
        activate: false,
        openTime: "Start",
        closeTime: "End"
      }
    ],
    selectedDay: null,
    showWorkingDays: false,
    PrevshowWorkingDays: {
      activate: false,
      openTime: "Start",
      closeTime: "End"
    },
  },
  reducers: BusinessSetting,
  extraReducers: ExtraReducersSettings,
});

export const { setActivate, setStatus, setPause, SetSettingControl, toggleDay, setSelectedDay, toggleWorkingDaysVisibility, toggleCustomHours, ResetCustomHours, handleCustomHours, SetHoursValidation, HandleCopyToAll, updateCustomHoursFromResponse, toggleWorkingDays } = BusinessSettingSlice.actions;
export const BusinessSettingReducer = BusinessSettingSlice.reducer;
