import React, { Fragment, useEffect, useState } from 'react'
import { useChatContext } from '../Store/ChatProvider';
import { utils } from '../../utils/Utils';

export const Groupicon = (props) => {

    const { renderState } = useChatContext();
    const [data, setdata] = useState([]);
    useEffect(() => {
        if (props.data) {
            setdata(props.data);
        }
    }, [renderState, props.data])


    return (
        <div className="avatar avatar-group avatar-md" data-avatar={data.length > 4 ? 4 : data.length}>
            {data.map((a, index) => {
                if (index < 3) {
                    return (
                        <Fragment>
                            <div className="avatar-group-img-wrap">
                                {(a.photo !== undefined && a.photo !== "" && a.photo !== null) ?
                                    <img alt='avtar' className="avatar-group-member" src={a.cdnUrl + 'Original/' + a.photo} />
                                    : <div> {utils.setLetter(a.firstName || "", a.lastName || "")}</div>
                                }
                            </div>
                        </Fragment>
                    )
                }
            })}
            {data.length > 3 &&
                <div className="avatar-group-img-wrap">
                    <div className="avatar-group-img-wrap-text">
                        +{data.length - 3}
                    </div>
                </div>
            }
        </div>
    )
}
export const Usericon = (props) => {
    const { URL, Image, FirstName, LastName, Mid = false } = props;
    return (
        <div className={Mid ? "avatar avatar-md avatar-md m-auto" : "avatar avatar-md"} data-avatar="1">
            <div className="avatar-group-img-wrap">
                {(Image !== undefined && Image !== "" && Image !== null) ?
                    <img alt='avtar' className="avatar-group-member" src={URL + 'Original/' + Image} />
                    : <div> {utils.setLetter(FirstName || "", LastName || "")}</div>
                }
            </div>
        </div>
    )
}
